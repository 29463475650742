/*-----Tags-----*/

.tag {
  background-color: #282828;
  border-radius: 56px;
  padding: 0.25rem 1rem;
  color: #d4d4d4;
  display: inline;
}

input.react-tags__search-input {
  width: 250px !important;
}

/*---------------*/

/*-----Modal-----*/
.modal-body-auto-height {
  height: auto !important;
}

.modal-dialog-confirmation {
  width: 60%;
  max-width: none !important;
  height: 230px !important;
}

.modal-dialog {
  height: 95vh;
}

.modal-dialog .modal-content {
  flex: 1 1 auto !important;
  height: 100%;
}

.modal-dialog .modal-body {
  display: flex;
  flex-direction: column;
}

.row.card-container {
  height: 0px;
  overflow-y: auto;
  margin: 2rem 0rem;
}

/* width */
.card-container::-webkit-scrollbar {
  width: 14px;
  background: #3e3e3e;
}

/* Track */
.card-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #3e3e3e;
  border-radius: 10px;
}

/* Handle */
.card-container::-webkit-scrollbar-thumb {
  background: #ffffff;
  width: 13px;
  border-radius: 7px;
}

.modal-dialog .card-container {
  padding-top: 0px;
}

.modal-dialog .modal-body {
  padding: 1rem 2rem;
}

.modal-dialog .modal-header {
  border-bottom: none;
  padding: 1rem 2rem;
}

.modal-dialog .modal-footer {
  border-top: none;
  justify-content: center;
  padding: 2rem;
}

.modal-dialog .modal-footer button {
  width: 175px;
  height: 45px;
}
/*---------------*/

.pb-2-half {
  padding-bottom: 0.75rem !important;
}

.me-2-half {
  margin-right: 0.75rem !important;
}

/*------React Table-------*/
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: black;
  border: none;
}

.table-bordered > :not(caption) > * {
  border: none;
}

.table > :not(:first-child) {
  border: none;
}

.table-dark {
  --bs-table-bg: #111110;
  --bs-table-striped-bg: #222222;
}

/*------Multi select component-------*/
.Mui-selected {
  background-color: #CCFF00 !important;
}

/*------React Player Component-------*/

.videoProgress input[type='range'] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#fa9e04, #fa9e04);
  background-size: 70% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
.videoProgress input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fa9e04;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.videoProgress input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fa9e04;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.videoProgress input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #fa9e04;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.videoProgress input[type='range']::-webkit-slider-thumb:hover {
  background: #fa9e04;
}

.videoProgress input[type='range']::-moz-range-thumb:hover {
  background: #fa9e04;
}

.videoProgress input[type='range']::-ms-thumb:hover {
  background: #fa9e04;
}

/* Input Track */
.videoProgress input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.videoProgress input[type='range']::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.videoProgress input[type='range']::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

/*-------------------------------------*/

/*------Date Picker Input component-------*/
.custom-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 8px);
  margin-bottom: 0;
}

.custom-datepicker-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 8px);
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-datepicker-label {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-datepicker-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 8px);
  padding: 0.475rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.custom-datepicker-input:lang(en) ~ .custom-datepicker-label::after {
  content: 'Select';
}
.custom-datepicker-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.475rem 1.5rem;
  line-height: 1.5;
  color: #fff;
  content: 'Select';
  font-size: 16px;
  background-color: #4D4E49;
  border-color: #4D4E49;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: 'pointer';
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #4D4E49 !important;
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #4D4E49 !important;
  color: #fff;
}

.option-text-dropdown {
  background: #ffffff;
  color: rgb(255, 158, 0);
}

.select-text-dropdown .option-text-dropdown:checked,
.select-text-dropdown .option-text-dropdown:focus {
  background: linear-gradient(rgb(255, 158, 0), rgb(255, 158, 0));
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
}
/*-------------*/

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: rgb(206, 206, 206);
}

*::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
}

*::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
}

.verticalScroll::-webkit-scrollbar-track {
  background: rgb(206, 206, 206);
  border-radius: 7px;
  margin-bottom: 10px;
}

.verticalScroll::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 7px;
  margin-bottom: 10px;
}

.horizontalContainer::-webkit-scrollbar-track {
  background: rgb(206, 206, 206);
  border-radius: 7px;
  margin-bottom: 10px;
}

.horizontalContainer::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 7px;
  margin-bottom: 10px;
}

.horizontalContainer::-webkit-scrollbar {
  height: 8px;
}

/*------Breaking text-------*/

.breaking-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/*-------------*/

.toggle-button > label:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle-button > label:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*------search text style-------*/
.css-dark-search::placeholder {
  color: #fff;
  opacity: 1;
}

.css-dark-search:-ms-input-placeholder {
  color: #fff;
}

.css-dark-search:-ms-input-placeholder {
  color: #fff;
}
/*-------------*/
