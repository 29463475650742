.modal-dialog {
  height: 95vh;
}

.modal-dialog .modal-content{
  flex: 1 1 auto !important;
  height: 100%;
} 

.modal-dialog .modal-body{
  display: flex;
  flex-direction: column;
}

.row.card-container {
  height: 0px;
  overflow-y: auto;
  margin: 2rem 0rem;
}

/* width */
.card-container::-webkit-scrollbar {
  width: 14px;
  background: #3e3e3e;
}

/* Track */
.card-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #3E3E3E;
  border-radius: 10px;
} 

/* Handle */
.card-container::-webkit-scrollbar-thumb {
  background: #ffffff;
  width: 13px;
  border-radius: 7px;
}

.modal-dialog .card-container{
  padding-top: 0px;
}

.modal-dialog .modal-body{
  padding: 1rem 2rem;
}

.modal-dialog .modal-header{
  border-bottom: none;
  padding: 1rem 2rem;
}

.modal-dialog .modal-footer {
  border-top: none;
  justify-content: center;
  padding: 2rem;
}

.modal-dialog .modal-footer button{
  width: 175px;
  height: 45px;
}